@import "variables";

//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

// Textsymbol

.textsymbol {
    position: relative;
    padding-top: 45px;
    padding-left: 50px;
    @include font-size($h3-font-size);
    font-weight: $font-weight-semibold;
    line-height: 1.4166667;


    @include media-breakpoint-down(sm) {
        .frame-inner & {
            margin-bottom: 2rem;
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: 80px;
        padding-left: 100px;
        @include font-size($h3-font-size-lg);
    }

    .textsymbol-icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 64px;
        height: 64px;
        background-size: contain;
        background-repeat: no-repeat;

        @include media-breakpoint-up(lg) {
            width: 100px;
            height: 100px;
        }

        &.symbol-plus {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAAAkFBMVEUAAAA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TDXDSxNAAAAL3RSTlMABgkgDDspGvt69VwyFIZXS55QRy2ugHNol0RA26eSNqONJe7TbGLKurQQ6cTkv5kG3xYAAANYSURBVGje7ZjZztMwEEbt7PvWJemWdN/+lnn/t8OZzwVRQAjhClBzbk7bi1iqfORxxMDAwJ8z2rcOPLv2dvdrtkmcG5ErFCeiSklOYKPImk6+UCjbvWPae8I0svPhBBaJLQbegOKWcSfzWym5kxt6MYl1IUqE4kw0UpIlUSEMI6c79BHu0Mdht3xBJ3PdxeLhrRj4yzjPlsI47nlqsS/oJbls2CaxPunz5KbPk9VLOqEl713lbe+G7mNhGkd3YbkeVlUeeAPcc8j1dZeMv3tn9AIMnycXCvg8mb2ik02EPpR5sTD6MN+JFY/hPMGqeScGXoz1C8vfMT58x2hV8a/FKkYX61jCDTspG15uXNZsrwzZ21nIvdiz7Nrbb7OUvWk98cyS7raSs6SIfaLIU5YnChLdSffN3AUL5bh3pr0hqnHFoJN45ky3VMmZ0CnFobT38bAlu6U7OqEPXjykHS8aUuD2PlK0gHE5qoha8Yxd2Wy/2sKFx06rMVzA1wJ9OEUCj1y2fHg+F8x84YiBf5/RpWAvJjk6uaCXbnJkjycN21vVbHsVSnY5ZadlZrFnmMectrXFM3uMPOlJ9/JJb1Vl7mSCXsSEojm2NDqZUZBjVKKHD70PRGfxzCe6+UrWGX04qhMbD116OKTuHj80uLv8sCDqOMJgV3B8QfRw3DsOgrV4ZhuiCztED37YfeM0XKCTeo4+6oJtHSrtXDDHWPsoxcC/j5u52iN2ko0k95Hl6CPL0cU0/sZ+2FjwAZ3UB4cd1j/shLfunj58dLLbopNo3PtrJ8FCz11zdPKlj0q7+WknN5wjzpmWKeJb2nj4B7uknYdDKkr67xlFLneiFzvoOGsidEL0fSdeM9ZO0E3ToZsGffjaaVPAR/yd12PFdh6OcwtzWp6Kgf8fe4Ze/DZmp+1RmMZaoRexoh13skEvRrHWtHtexBWm8dsK3uTs6yYWA2+AfZjj/DgUgt0UUhhGrnFfEWsKut6v2MLya4yBq4e7hTDNdlrh2A1jAefDXPUOpNMRHKITJ6yEaawS9xWh7D5GIuOLqD7GT5cgZcN4+lpnrxu2v67FwBvg5wn7mrtfbRg5o48t7iMYjaYUdcIsX18WXNCHLGGzJPo1obeu2fx6cGBgwDifAdUfTtYRBeQUAAAAAElFTkSuQmCC');
        }

        &.symbol-arrow_left {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAAAmVBMVEUAAAA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TAbLI0pAAAAMnRSTlMACQcDRSgdFREsGQw5I+XOupY+NQ74hDAhqp2LeFlQTGtV8cC0Zcfd2NKmXq99c2Gi6m4ud/MAAAMWSURBVGje7ZnZzpswEIXH7BD2HRL2kD1Nfr//w1WM3Vaqqt506Ca+q6NccBLhDwcPbGxs0JLWwV2FlYkDHjmwMs3IQxtWxqtPfQpr4xUmbPwVMEuRIZVhl1L7orRPTcWO5ulisOYD9VIuTvyoL0G78NpYWhuf74EOee2TizJ+iDb2KeNvoCV9HvvdEpz9sWEAoOp11QExZmnJYGAZqI7BYOMPwJydVM9hIqSOlNEyVaKONpp32HGPBoYdfaQpeN8PexdI0EN+iZfgBnxCwTufV+VSP488IhJ9yq6a2HWzKVnC4GcVytiPWQ0kKM1bOMj6d6PgnTjUHd4LI49iIGpJmQjsW1CEjLudAhu/D6U0pYOeDJaXimB6jMrBKjew41UdTOz4VM0pduTVQNPiPfjYyA3xLDy58FOxhHbkRw8oKOvx0i7BPo4nYfxpPGJJdxnfHpDg5vMOg5YPClox5Bp+wF65Cxv/GaruSVFsQzpoO9JB2wIa2vCWqOjgFHnCwWmPdU4+oZUEvDk/sEXGI8/uKGPAfQ2Xss+DBEjI/fN9KTEiP8Br67ePEK+dhB+VDiSUc5NisPtWEVb2GiBavzn4/8LcQjro6iI4sdSzjA2g4RWEsYoOXitb7IPXGluM6Lp3gIQb508m/p1mL9Qj4H4nZbwSreHDJUA/zPw8xeIHnB82ilOd6xJIcFqNYSiHWMWgD/Jhkgw2bPz3OJ0rgtdKPe1WWul2RFaauR+6IDaWShcbix8ZYmPxcxMI+JmM94zqoNM6BI9CnKTIx0pZy8dK8QioNnsnlt+21HQRdE0Kb2sObPxJVKaIoDD1B4GGZI5FiOdEhG4WS4ENLyIry1sWFrimwuzmyfMPtFIZzlkOJHyT8cwDIePIQ3HQyXkFRFZOe0dYOT1NMXWY8E1PTW5hAzSYhSGCUUjPS/kOoXr2DjZ+Abqpw85adeqgYNldTh3MnnzqkHydOpzl1OH+8W9OHazD48vU4SGnDtGtA+oWI/1u6mBuU4e/BctRYG2sT5G2doucOqzM4POHDitjB3xvwcbGxhp8BlhGPNbxzFyvAAAAAElFTkSuQmCC');
        }

        &.symbol-arrow_right {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAAAkFBMVEUAAAA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TDXDSxNAAAAL3RSTlMABQkjGhANRTguKhdTM6nmz7poPxP4hHhNomDbix/Jm7+0cCbxrltY05bhfZPsw/JmjE4AAAMPSURBVGje7ZnZbtswEEW1UAu176u1WLIsK3bC//+7QkO6Kdo+FaMkDXhffKEHXZjDoyExipSU1D/IOLGIKgfLWVjoKQdLD1hYKQdLtbbSUI6WSgxF6ktII64KxiSZIKZDL04VbDOErcuJQFiyoG+zmuWTtsN4Zq/r/sDuWWshh5zYa7OvV1fDuyGt95FDaJlkgIl9bzRO5d1TkUNUw/zDaIrUZ8qkugomo7oCcinhhlAXKWSNA18FBsdhhrD7eHIh4zRiURkwVgKMMbtcAcaIvQAw1QvrbZyQIm8BC/LI+3R/MAd5THfjx3mt44R0jcexcBLRff0k5SZN0MjXVMHgX43UZ4pYlBvdmrlxrI4bmhKcjKyMFsoZvA06hA03TqW/RChUAoz5bzCGLAIYm5zFDkqIW4SxD+BsYTBDbBBuBGLjsMiQalLZoiYe5YZ6Ani7IorUt5ez+tz4qwO/aip2gGZ5SDtAH9oAXk6XdgDk07Et3D3Mi9oSJ8S+sfAnjP4Txp0c7c5YjcTL6bZ14rMCf0ChwW0yYNniqFGQUlLBoJ66okq2IfC05eHy+8qeLG6syeYwVgkFY66JjgR8IBqLHYvGYvXhg+ycNG1YoGS8d0brRdzrrjk763tIiQZjVo7F88A9QUOch7Ex92Xz69hTkFKoYJBQwWBHNYGnI8+U30520WicwcJTgUGvsDiDTWEjNcT60la7oecLv8Wl/eUMpHvtBenKNZ/ZK0Dg96xNBYxvELJe2IIToq0BZ9BMginjVAbAoEKKwENqVpouGDR1Q1Cpm9y4umTwI6Wa5tNoojjvBqsU6VYQ3v6G0gBzrRONb7P6ipQy5Bd457zkL5yTMY84J1E++jghG4OQ96kDHVnEib9hXRUV+1Ty5bIekwGV8B6cQTN5YDGoutnTCAY11xRUuvJQ+inSOiKmDp37nDpk2CHVMjgQdj2LqcN0vv+nU4fwKqYOt+fU4c1HDnHKxBBTh+tz6lCh96hfhg1y6vDFlPm6crSMqa+PThFTh4NFNgZd91g10SBn5VJSH64fGjM2W0uNEwMAAAAASUVORK5CYII=');
        }

        &.symbol-check {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAAAkFBMVEUAAAA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TA50TDXDSxNAAAAL3RSTlMAAgsHBA4ZEh8WL548JMBY9mN5Ryvll4JzKDfGuWxdUEI01Yt+U96qS/CmzrWvkjFSDYUAAAPISURBVGje7ZbX0pswEIVRofdeDC4Ud2ff/+2iFSSZjDNh/jHORcy5+UYaoSNY7bLKqlWrVq36V1IdiwlwyzGVd4n4wTERLD2IlHeJ95dLJbi/BZnyLhE92lLkEJXKqg8QLTahADncM03Q7LyzANt5OV3OxNrARoDnAI5gfAQbU/MEgbGciR55hQBx7jbuanS1L8B8r1OVBV0SFUESjSC1kiFYohNl1T+WOgymAKtCS4CY25gK0v3WWNAkDmAnYNzhIcAbCNDs0EK3oMmuTdHEvaYnNCnS9CDoeCmaLOdypohk/D7Mdxiy8k1l1b8WoSNVLsF/DBet7U2aEVl+b5WA3qUROsdt7S5nol0hxVNvAbZPpX4psbPdcDTrOjw63dq+LMJZvuTdZRqVNMdiTjU2vqG61vZFdMhlc6k3vUtm727YJwLcyQdcaxaRgbPnfD/zYAZHWaABGjrbl1wgImJzG464dgfgC7ge1DMPRu0pwft3a302Z5LUbShM9L59oMl+08YCRtZmc5+gciXdSplXWVGE6mjjp044QnN05f8XmYAcMfFJz4t+zs5ItxiCuhqCaCWRs6WpPIuNixTDHWNS6mMcDT5jsgkKVaAIZIEuj5cBTbPAfo4iD4Obhh63IESPPLjj7s7xMpcgAJkp86StxItUAAUV29VQG883sIcgwduUQk/EbRJ5gufzA/BnTPw+kX+eyKd4ujDCdpnso5g/R8SNtkxmfFEK8EO/I/hM02gzJlyVIJRJMnWa5X+KuzouopSMQz4NP6+2czKS/QbCZ2a/pNiOdPm/ti1029ohFZuV2TdDDFlhY4iJY+cqJlFuO2ixs5uvBcMG2SZPV5h4sNEEtgBnLOopXMUszQFKvMIB5Dh7hZR+yST0MgOTt/bw7pLey3U8ulfji6nfvIaJow/eSSbj1RtwtvEy9rUKZuiSuiHBDCqpaRKqwSSnRaZBENylyoeL8AlkJPltiBgXvSJWxbJGuHGJoI7DcVMrNmTA4gPBYbV/qRG1AgiZ4AkeYh+yg2CPLcMGvjFMF0jRLDlC8VL7BdKEPOCOeTIAoIl5gwzzpICLiyd50YTtfdkuJ76F0Hc7JkAc3yUC2oCWCo8H7bXAswlTpPlvwwmcfV5t/6uIxiR1XYJqRNKkS3pEdaRiQtwfmC9qV4dchOJc2+aCJptfpZ4/lfqlFJ4aipl+tbHg0v7k45vsT98W7anV6c7SqU2ZZrmy6i9iTIK/s30zw5AKkLix3uZCfIAYW+sWbKq8SeQMFweT0YOOKatWrVq1atUH6Ds/bj4DosphIQAAAABJRU5ErkJggg==');
        }
    }

}
